import React from 'react';
import { Link } from "gatsby";

import MapImg from "../../assets/images/map.png";
import GDSCLogo from "../../assets/images/cloudCampaign/gdscLogo.svg"

const FunFactsArea = () => {
    return (
        <div className="funfacts-area ptb-80">
            <div className="container">
                <div className="section-title">
                    <img src={GDSCLogo} alt="Google Developer Student Club" width="50%" height="50%"/>
                    <div className="bar"></div>
                    <p>Google Developer Student Clubs (GDSC) are community groups for college and university students interested in Google developer technologies. Students from all undergraduate or graduate programs with an interest in growing as a developer are welcome. By joining a GDSC, students grow their knowledge in a peer-to-peer learning environment and build solutions for local businesses and their community.</p>
                </div>

                <div className="row">
                    <div className="col-lg-3 col-md-3 col-6">
                        <div className="funfact">
                            <h3>100+</h3>
                            <p>Countries</p>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-3 col-6">
                        <div className="funfact">
                            <h3>1,200+</h3>
                            <p>Chapters</p>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-3 col-6">
                        <div className="funfact">
                            <h3>1m+</h3>
                            <p>Dev Reach</p>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-3 col-6">
                        <div className="funfact">
                            <h3>900+</h3>
                            <p>Projects</p>
                        </div>
                    </div>
                </div>

                <div className="contact-cta-box">
                    <h3>Have Any Questions About Us?</h3>
                    <p>Don't hesitate to contact or join us</p>

                    <Link to="https://developers.google.com/community/gdsc" className="btn btn-primary">
                        Join here
                    </Link>
                </div>

                <div className="map-bg">
                    <img src={MapImg} alt="map" />
                </div>
            </div>
        </div>
    )
}

export default FunFactsArea;