import React from 'react';
import * as Icon from 'react-feather';

import Team1 from '../../assets/images/team-image/team1.jpg'
import Team2 from '../../assets/images/team-image/team2.jpg'
import Team3 from '../../assets/images/team-image/team3.jpg'
import Team4 from '../../assets/images/team-image/team4.jpg'
import Team5 from '../../assets/images/team-image/team5.jpg'
import Dalton from '../../assets/images/cloudCampaign/TeamMembers/Dalton.jpeg'
import Sanaa from '../../assets/images/cloudCampaign/TeamMembers/Sanaa.jpeg'
import Rene from '../../assets/images/cloudCampaign/TeamMembers/Rene.jpeg'
import Saurabh from '../../assets/images/cloudCampaign/TeamMembers/Saurabh.jpeg'
import Madusha from '../../assets/images/madusha.jpeg'
import Andrii from '../../assets/images/cloudCampaign/TeamMembers/Andrii.jpeg'
import Benjamin from '../../assets/images/cloudCampaign/TeamMembers/Benjamin.jpeg'
import Daniel from '../../assets/images/cloudCampaign/TeamMembers/Daniel.jpeg'
import Gargi from '../../assets/images/cloudCampaign/TeamMembers/Gargi.jpeg'
import Shun from '../../assets/images/cloudCampaign/TeamMembers/Shun.jpeg'
import Hoda from '../../assets/images/cloudCampaign/TeamMembers/Hoda.jpeg'
import Henry from '../../assets/images/cloudCampaign/TeamMembers/Henry.jpeg'
import Unknown from '../../assets/images/cloudCampaign/TeamMembers/unknown.png'


import Loadable from '@loadable/component'
const OwlCarousel = Loadable(() => import('react-owl-carousel3'))

const options = {
    items: 5,
    loop: true,
    nav: false,
    dots: true,
    margin: 30,
    autoplay: false,
    smartSpeed: 1000,
    responsive: {
        0: {
            items: 1
        },
        576: {
            items: 2
        },
        900: {
            items: 3
        },
        1200: {
            items: 4
        },
        1500: {
            items: 5
        }
    }
}

const Team = () => {
    const [display, setDisplay] = React.useState(false);

    React.useEffect(() => {
        setDisplay(true);
    }, [])

    const data = [
        // {
        //     name: 'Madusha Cooray',
        //     role: 'Community Manager',
        //     linkedin: 'https://www.linkedin.com/in/madushacooray/',
        //     bio: '',
        //     image: Madusha,
        // },
        // {
        //     name: 'Daniel Fiorilo',
        //     role: 'Community Manager',
        //     linkedin: 'https://www.linkedin.com/in/djf19/',
        //     bio: '',
        //     image: Daniel,
        // },
        {
            name: 'Dalton Leight',
            role: 'Backend Developer',
            linkedin: 'https://www.linkedin.com/in/dalton-leight/',
            bio: '',
            image: Dalton
        }, 
        {
            name: 'Sanaa Syed',
            role: 'Frontend Developer',
            linkedin: 'https://www.linkedin.com/in/sanaasy/',
            bio: '',
            image: Sanaa
        },
        {
            name: 'René Capella',
            role: 'Project Manager',
            linkedin: 'https://www.linkedin.com/in/amandarenecapella/',
            bio: '',
            image: Rene
        },
        {
            name: 'Saurabh Kishore',
            role: 'Frontend Developer',
            linkedin: 'https://www.linkedin.com/in/saurabh-gummaraj-kishore/',
            bio: '',
            image: Saurabh
        },
        {
            name: 'Yasmine Siddiqi',
            role: 'Content and Marketing',
            linkedin: 'https://www.linkedin.com/in/yasmine-jacob-siddiqui-95344020b/',
            bio: '',
            image: Unknown
        },
        {
            name: 'Shun Ueda',
            role: 'Backend Developer',
            linkedin: 'https://www.linkedin.com/in/shun-ueda-720758219/',
            bio: '',
            image: Shun
        },
        {
            name: 'Gargi Thakur',
            role: 'Content and Marketing',
            linkedin: 'https://www.linkedin.com/in/gargithakur94/',
            bio: '',
            image: Gargi
        },
        {
            name: 'Benjamin Thaw',
            role: 'Backend Developer',
            linkedin: 'https://www.linkedin.com/in/benjamin-thaw/',
            bio: '',
            image: Benjamin
        },
        {
            name: 'Andrii Denysenko',
            role: 'Backend Developer',
            linkedin: 'https://www.linkedin.com/in/andrii-denysenko/',
            bio: '',
            image: Andrii
        },
        {
            name: 'Hoda Abokhadra',
            role: 'Content and Marketing',
            linkedin: 'https://www.linkedin.com/in/hoda-abokhadra/',
            bio: '',
            image: Hoda
        },
        {
            name: 'Henry Ruiz',
            role: 'Backend Developer/Speaker',
            linkedin: 'https://www.linkedin.com/in/haruiz/',
            bio: '',
            image: Henry
        },
        {
            name: 'Raahil Jain',
            role: 'Content and Marketing',
            linkedin: 'https://www.linkedin.com/in/raahil/',
            bio: '',
            image: Unknown
        },
    ]

    return (
        <div className="team-area ptb-80 bg-f9f6f6">
            <div className="container">
                <div className="section-title">
                    <h2>Our Awesome Team</h2>
                    <div className="bar"></div>
                    <p>Meet the team behind 30 days of Cloud!</p>
                </div>
            </div>

            {display ? <OwlCarousel 
                className="team-slider owl-carousel owl-theme owl-theme-style"
                {...options}
            > 
                {data.map(member => (
                    <div className="single-team">
                        <div className="team-image">
                            <img src={member.image} width="200em" height="200em"  alt="image" />
                        </div>

                        <div className="team-content">
                            <div className="team-info">
                                <h3>{member.name}</h3>
                                <span>{member.role}</span>
                            </div>

                            <ul>
                                <li>
                                    <a href={member.linkedin} target="_blank" rel="noreferrer">
                                        <Icon.Linkedin />
                                    </a>
                                </li>
                            </ul>

                            <p>{member.bio}</p>
                        </div>
                    </div>
                ))}
            </OwlCarousel> : ''}
        </div>
    )
}

export default Team;